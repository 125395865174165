import { useNavigate } from 'react-router-dom';
import './profile.scss';
import MenuList from '../components/list/List';

const Profile = () => {
    const navigate = useNavigate();

    const menu = [
        {
            title: "YOUR REWARDS & BENEFITS",
            list: [
                {
                    name: 'shopping coupons',
                    icon: '/images/icons/shop.svg'
                },
                {
                    name: 'zuno coins',
                    icon: '/images/icons/coin.svg'
                }
            ]
        },
        {
            title: "MANAGEMENT",
            list: [
                {
                    name: 'Account settings',
                    icon: '/images/icons/profile.svg'
                },
                {
                    name: 'languages',
                    icon: '/images/icons/lang.svg'
                },
                {
                    name: 'Bank settings',
                    icon: '/images/icons/settings.svg'
                }
            ]
        },
        {
            title: "ABOUT",
            list: [
                {
                    name: 'terms and conditions',
                    icon: '/images/icons/T&C.svg'
                },
                {
                    name: 'sign out',
                    icon: '/images/icons/logout.svg'
                }
            ]
        }
    ]
    return (
        <div className="zuno-profile px-3 py-3">
            <div className="back-nav d-flex justify-content-between" onClick={() => {
                navigate('/home')
            }}>
                <button className="btn btn-link text-decoration-none rounded back-btn">
                    <i class="bi bi-arrow-left text-primary align-middle" style={{ fontSize: '24px' }}></i>
                    <span className="ps-2 mt-1 text-dark">Profile</span>
                </button>
                <button className="btn btn-outline-primary btn-sm support-btn rounded"> <i class="bi bi-chat-dots-fill"></i> Support</button>
            </div>
            <div className='user-profile d-flex justify-content-between mt-5'>
                <div className='user-details text-primary'>
                <img src={'/images/profile.png'} className='profile-img' />
<div>
                    <h5>Robert Downey Jr.</h5>
                    <p>member since Jun, 2024</p>
                    </div>
                </div>
                <div className='edit-icon'>
                    <img src={'/images/icons/edit.svg'} />
                </div>
            </div>
            <MenuList menuList={menu} />
        </div>
    )
}

export default Profile;